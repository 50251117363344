import React from 'react'


import DataTable from 'react-data-table-component'
import { formatRut } from '../../functions/utilities/format'



const ChoferesTable = ({values,handleModal}) => {


    const columns = [
        {
          name: 'ID',
          selector: row => row.id,
          width:'60px'
        },
        {
            name: 'Nombre Completo',
            selector: row => row.nombre_completo
        },
        {
            name: 'Rut',
            selector: row => row.rut
        },
        {
            name: 'Tipo Licencia',
            selector: row => row.tl
        },
        {
            name: 'Fecha Vencimiento Licencia',
            selector: row => row.fvl
        },
        {
            name: 'Detalle',
            selector: row => row.detalle
        }
    ]


    const data = values && values.map( c => {
        return {
            id:c.id,
            nombre_completo:c.nombre_completo,
            rut:formatRut(c.rut),
            tl:c.tipo_licencia,
            fvl:c.vencimiento_licencia,
            detalle: <button onClick={handleModal} value={c.id} className='btn btn-primary'> <i class="fa-solid fa-magnifying-glass"></i> Detalle</button>
            
        }
    })

  return (
    <DataTable
    data={data}
    columns={columns}
    pagination
    />
  )
}

export default ChoferesTable