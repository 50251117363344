import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

// Componentes
import AdminNav from '../../../components/navs/AdminNav'
import FlotaTable from '../../../components/tables/FlotaTable'
import FlotaCreate from './FlotaCreate'
import FlotaDetail from './FlotaDetail'
import Loading from '../../../components/others/loading'

// Funciones
import { getChoferes } from '../../../functions/api/choferes'
import { getFlota, createFlota, updateFlota } from '../../../functions/api/flota'


const initialState = {
  "placa_patente": "",
  "activo": true,
  "chofer_id": ''
}

const TipoContenedoresList = () => {

  const [valuesUpdate, setValuesUpdate] = useState([])
  const [values, setValues] = useState(initialState)
  const [flota, setFlota] = useState([])
  const [conductores, setConductores] = useState([])
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(100)
  const [isChecked, setIsChecked] = useState(false);
  const id = useRef()

  let { user } = useSelector((state) => ({ ...state }))

  useEffect(() => {

    loadFlota()
    loadConductores()

  }, []);

  const loadFlota = () => getFlota(user.token, page, perPage).then(res => setFlota(res.data)).catch(err => console.log(err))
  const loadConductores = () => getChoferes(user.token, page, perPage).then(res => setConductores(res.data)).catch(err => console.log(err))


  const handleModal = (e) => {
    e.preventDefault(e)
    id.current = 1
    if (modal === false) {
      setLoading(true)
      id.current = e.target.value
      let filteredData = flota.filter(d => d.id === Number(id.current));
      setValuesUpdate(filteredData[0])
      setLoading(false)
    } else {
      setValuesUpdate([])
    }
    setModal(!modal)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    setLoading(true)
    if (values.chofer_id === '' || values.placa_patente === '') {
      toast.error('Seleccione Chofer e ingrese patente de camión.')
      setLoading(false)
    } else {
      createFlota(user.token, values)
        .then(res => {
          toast.success('Camión creado exitosamente')
          loadFlota()
          setValues(initialState)
          setLoading(false)
        })
        .catch(err => {
          setLoading(false)
          toast.error(err.response.data.detail)
        })
    }

  }

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const handleChangeUpdate = (e) => {
    setValuesUpdate({ ...valuesUpdate, [e.target.name]: e.target.value })
  }

  const handleUpdate = (e) => {
    e.preventDefault()


    let data = {}
    data.placa_patente = valuesUpdate.placa_patente
    data.chofer_id = valuesUpdate.chofer_id
    data.activo = true

    setLoading(true)
    if (data.chofer_id === '' || data.placa_patente === '') {
      toast.error('Seleccione Chofer e ingrese patente de camión.')
      setLoading(false)
    } else {
    updateFlota(user.token, Number(id.current), data)
      .then(res => {
        toast.success('Camión actualizado exitosamente')
        loadFlota()
        setLoading(false)
      })
      .catch(err => {
        toast.error(err.response.data.detail)
        setLoading(false)

      })
    }
    setValuesUpdate([])
    setModal(!modal)

  }



  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        modal && valuesUpdate && valuesUpdate.length !== 0 && (
          <FlotaDetail
            handleModal={handleModal}
            valuesUpdate={valuesUpdate}
            handleChangeUpdate={handleChangeUpdate}
            handleUpdate={handleUpdate}
            conductores={conductores}
          />
        ))}
      <AdminNav />
      <div className="subNav">
        <span>Admin / Flota</span>
      </div>
      <div className='contenedorTm'>
        <div className="form-container">
          <FlotaCreate
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            values={values}
            conductores={conductores}
          />
        </div>
        <div className="table-title">
          <h6 >FLOTA</h6>
        </div>
        <div className="table-container">
          <FlotaTable
            values={flota}
            handleModal={handleModal}
            conductores={conductores}
          />
        </div>
      </div>
    </div>
  )
}

export default TipoContenedoresList