import React from 'react'
import { formatRut } from '../../../functions/utilities/format'

const ChoferDetail = ({ handleModal, handleUpdate, handleChangeUpdate, valuesUpdate }) => {



    const { nombre_completo, rut, tipo_licencia, vencimiento_licencia, fecha_nacimiento } = valuesUpdate


    return (
        <>
            <div className='modalBackground' onClick={handleModal}>
            </div>
            <div className='modalCard'>
                <div className="row">
                    <div className="col-md-12">
                        <p className='modalTitle'>ACTUALIZAR CONDUCTOR</p>
                    </div>

                    <div className="col-md-2 mt-2 ">
                        <label className='label-title'>Rut</label>
                        <br />
                        <input className='loginInput' value={formatRut(rut)} name='rut' maxLength='12' onChange={handleChangeUpdate} />
                    </div>
                    <div className="col-md-4 mt-2 ">
                        <label className='label-title'>Nombre</label>
                        <br />
                        <input className='loginInput' value={nombre_completo} name='nombre_completo' onChange={handleChangeUpdate} />

                    </div>
                    <div className="col-md-3 mt-2 ">
                        <label className='label-title'>Tipo de licencia</label>
                        <br />
                        <select className='loginInput' value={tipo_licencia} name='tipo_licencia' onChange={handleChangeUpdate}>
                            <option>Seleccionar tipo de licencia</option>
                            <option value='Licencia A5'>Licencia A5</option>
                            <option value='Ley antigua 18.290'>Ley antigua 18.290</option>

                        </select>
                    </div>
                    <div className="col-md-3 mt-2  ">
                        <label className='label-title'>Vencimiento licencia</label>
                        <br />
                        <input className='loginInput' type='date' value={vencimiento_licencia} name='vencimiento_licencia' onChange={handleChangeUpdate} />

                    </div>
                    <div className='col-md-12 mt-3'>
                        <button onClick={handleUpdate} className='btn-blue'> <i class="fa-regular fa-floppy-disk"></i> Guardar</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChoferDetail